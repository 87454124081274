var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "dn-article" }, [
    _c("div", { staticClass: "dn-article__header" }, [
      _c("div", _vm._b({}, "div", _vm.articleBgAttrs, false)),
      _c(
        "div",
        { staticClass: "dn-container" },
        [
          _c("back-button", {
            on: {
              back: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c("h1", { staticClass: "dn-article__title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("p", { staticClass: "dn-article__subtitle" }, [
            _vm._v(_vm._s(_vm.current.subtitle)),
          ]),
          _c("p", { staticClass: "dn-article__date" }, [
            _vm._v(
              _vm._s(_vm.publishDate) + " | " + _vm._s(_vm.current.author)
            ),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dn-article__body" }, [
      _c("div", {
        staticClass: "dn-article__content",
        domProps: { innerHTML: _vm._s(_vm.current.content) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }